<template>
  <div style="background-color: #EBEED7;">
   <section>
      <article
        style="padding-top: 160px;">
      
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="article_intro">
            <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN INITIATIVES</small>
                  </p>
            <h2 style="font-weight: 600; font-size: 2em">
              Women Empowerment
            </h2>
            <p style="">
                More than half of the world’s in habitants live in cities and this trend is 
                expected to continue. By 2050, more than two thirds of the world population 
                will be urban dwellers. This is why SDG 11 is important to us; it means creating 
                career and business opportunities, safe and affordable housing and resilient 
                societies and economies. Sustainability is made up of 3 pillars namely; 
                the economy, society, and the environment.
            </p>
            <p style="">
                Kinawataka Women Initiatives deals specifically in recycling used straws 
                demonstrating a natural combination of aiding ideas for employment, community 
                support, environmental concerns leading to action and empowerment of women and youth.
            </p>
            <p style="">
                The environmental effects are multiple: plastic was initially dumped in drainage 
                channels, littered on roads or burned hence polluting the environment, causing 
                floods, water borne – diseases, and other damages. The cleaning and re-use of 
                plastic straws helps in solving the plastic burden, the straw bags are of multiple 
                usage and stronger than the paper bags that are now replacing the single-use “Kavera”.
            </p>
            <p style="">
                The members of the Kinawataka Women Initiative recycle used straws in their homes 
                to provide an income for their households. The sale of the straw products allows 
                the women to purchase scholastic materials, pay school fees, and cover food and 
                healthcare costs.
            </p>
            <p style="">
                In 2010, Kinawataka Women Initiatives (KIWOI) was listed as an international vendor 
                for Coca cola waste straws which saw the organisation receive more orders. KIWOI 
                started training women and youth especially in the urban centers who also train 
                others. The concentration in urban centers is because these straws (ones used for drinking) 
                are majorly a common environmental burden in these areas since it is where majority of 
                the soft drinks which are taken using straws takes place (according to a Market Survey 
                by SABCO/Century Bottling Company, 2015).
            </p>
            <p style="">
                The action has been tested in Lukuli where over 50 women were trained and hence 
                their households have benefitted from a new skill and as a group they receive orders 
                of crafts like Bags, mats for the International School of Uganda and other 
                international clients. The project created jobs enhanced household income and improved 
                the environment.
            </p>
            <p style="">
                To date approximately 7940 women and single mothers and 6000 youths, school drop outs 
                as well as people living with HIV/AIDS have been skilled and empowered through KIWOI 
                either coming as individuals or through groups and associations. Some of these include; 
                Gulu Tax Center Women Group, Busia Municipal Women Group, Single Mothers Gaba Women 
                Group, Kawempe Municipal Women Group, Mukono St. Dustan Anglican Church, TASO Women 
                groups, Budaka Catholic Women group, women groups within Nakawa division and Luzira 
                Women ex-prisoners.
            </p>
            <p style="">
                During training, Artisans are equipped with modern knowledge and skills of production. 
                The skills acquired reduce the time an individual takes to produce items which are a 
                motivating element to the workers since they produce more quality products in shortest 
                time possible and earn more money. Earning here depends on products made/finished.
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
            
        </div>
      </article>
    </section>
    
  </div>
</template>

<script>

export default {

  data() {
    return {
        
    };
  },

  methods: {
      
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .locus_word_style {
        font-size: 0.5em; font-weight: 300;
        word-spacing: 8px;
        letter-spacing: 5px;
    }
    .article_intro {
        width: 80%;
    }

    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) { }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
        .article_intro {
            width: 100%;
        }
    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
        .article_intro {
            width: 100%;
        }
    }
</style>
